import React from 'react';
import CustomNavbar from './CustomNavbar';

const Share = () => {
        return (
            <div>
                <CustomNavbar />
                <div className="contact-us-container">
                    <div className="contact-box">
                        <h2>Coming Soon</h2>
                        
                    </div>
                </div>
            </div>
        );
    
};

export default Share;